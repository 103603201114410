@font-face {
  font-family: 'AmazonEmber';
  src: url('../assets/fonts/AmazonEmber_Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AmazonEmber';
  src: url('../assets/fonts/AmazonEmber_Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'AmazonEmber';
  src: url('../assets/fonts/AmazonEmber_Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'PTMono';
  src: url('../assets/fonts/PTMono_Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('../assets/fonts/SpaceMono_Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --AmazonEmber: 'AmazonEmber', sans-serif;
  --PTMono: 'PTMono', 'AmazonEmber', sans-serif;
  --SpaceMono: 'SpaceMono', 'AmazonEmber', sans-serif;
}

#main-layout {
  height: 100%;
}

body,
html,
#root {
  padding: 0px;
  min-height: 100%;
  font-family: --AmazonEmber;
  overflow-x: hidden;
  max-width: 1920px;
  margin: auto;
}

.pt-mono-font-family {
  font-family: var(--PTMono) !important;
}

.space-mono-font-family {
  font-family: var(--SpaceMono) !important;
}

$spaceamounts: (
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  80,
  95,
  100
); // Adjust this to include the pixel amounts you need.
// $sides: (t, b, l, r); // Leave this variable alone

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .mt-#{$space} {
    margin-top: #{$space}px !important;
  }

  .mb-#{$space} {
    margin-bottom: #{$space}px !important;
  }

  .mr-#{$space} {
    margin-right: #{$space}px !important;
  }

  .ml-#{$space} {
    margin-left: #{$space}px !important;
  }
  .my-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }
  .mx-#{$space} {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  .pt-#{$space} {
    padding-top: #{$space}px !important;
  }

  .pb-#{$space} {
    padding-bottom: #{$space}px !important;
  }

  .pr-#{$space} {
    padding-right: #{$space}px !important;
  }

  .pl-#{$space} {
    padding-left: #{$space}px !important;
  }
  .py-#{$space} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }
  .px-#{$space} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }
}

// $widthamounts: (25, 50, 75, 100);

// @each $width in $widthamounts {
//   .w-#{$width} {
//     width: #{$width}% !important;
//   }
// }

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-white {
  color: white;
}
.text-primary {
  color: #5c95ff;
}

.bg-primary {
  background-color: #5c95ff;
}

.text-danger {
  color: red;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.9s;
}
